import { StoryblokComponent, useStoryblokState } from '@storyblok/react'
import { MainMenuItems, PageStory, StoryblokStaticData, getMainMenuItems, getStoryblokStaticData, getStoryblokStory } from '@/services/Storyblok.service'
import { CookieBanner, GoogleTagManagerScripts, SEO, StoryblokStoryContext } from '@/components/dynamic'
import { MainMenuContext } from '@/components/dynamic/MainMenu'

export type HomeProps = {
  story: PageStory | null;
  data?: StoryblokStaticData;
  mainMenuItems?: MainMenuItems;
  currentPath?: string
};

export default function Home({ story, data, mainMenuItems, currentPath }: HomeProps) {
  const state = useStoryblokState(story, {
    resolveRelations: ['global_reference.reference', 'redirect.target_story'],
  })

  return (
    <>
      <SEO content={story?.content} canonical="/" />
      <StoryblokStoryContext.Provider value={data?.__next_ssg_data ?? {}}>
        <MainMenuContext.Provider value={{ currentPath: currentPath ?? null, items: mainMenuItems ?? [] }}>
          <StoryblokComponent blok={state?.content} />
          <CookieBanner />
          <GoogleTagManagerScripts />
        </MainMenuContext.Provider>
      </StoryblokStoryContext.Provider>
    </>
  )
}

export async function getStaticProps() {
  const story = await getStoryblokStory<PageStory>('home')

  if (!story) return { notFound: true }

  const data = await getStoryblokStaticData<HomeProps>(Home, { story })
  const mainMenuItems = await getMainMenuItems()

  return { props: { story, data, currentPath: 'home', mainMenuItems }, revalidate: 60 * 60 }
}
